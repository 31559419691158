import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import ContactForm from '../components/ContactForm'

const ContactPage = () => (
    <Layout>
        <SEO title="contact:title" />
        <ContactForm />
    </Layout>
)

export default ContactPage
